const messages = {
  ro: {
    app: {
      about: 'Despre aplicație',
      actions: {
        add: 'Adăuga',
        addNamed: 'Adăugați {name}',
        remove: 'Șterge',
        removeNamed: 'Șterge {name}',
        removeConfirmation: 'Chiar vrei sa anulezi?',
        more: 'Mai mult',
        less: 'Mai puțin',
        download: 'Descarca',
        save: 'Salvează',
        cancel: 'Anulează',
        close: 'Închide',
        moreFormFields: 'Mai multe câmpuri',
        export: 'Export',
        edit: 'Editare',
        createdSuccessMessage: 'Creat cu succes.',
        savedSuccessMessage: 'Salvat cu succes.',
        deletedSuccessMessage: 'Șters cu succes',
        detail: 'Detaliu',
        next: 'Următorul',
        back: 'Înapoi',
        start: 'Start',
        whatNext: 'Ce urmeaza...',
        confirm: 'Confirmare',
        confirmRemove: 'Chiar vrei sa anulezi?',
        search: 'Căutare',
        userProfile: 'Profil utilizator',
        tenantProfile: '',
        imports: 'Importurile',
        fileImports: 'Importați fișiere',
        settings: 'Setări',
        logout: 'Deconectați-vă',
        login: 'Autentificare',
        filterList: 'Lista de filtre',
        excel: 'Excel',
        refresh: 'Reîncarcă',
        clear: 'Curăța',
        agree: 'Sunt de acord',
        disagree: 'Nu sunt de acord',
        makeCopy: 'Fa o copie',
        fullscreen: 'Ecran complet',
        fullscreenClose: 'Închideți modul ecran complet',
        copy: 'Copie',
      },
      messages: {
        confirmRemove: 'Chiar vrei să elimini acest element?',
        errorOccurred: 'A apărut o eroare',
        createdSuccess: 'Crearea a avut succes.',
        savedSuccess: 'Salvarea a avut succes.',
        deletedSuccess: 'Ștergerea a avut succes.',
        uploadSuccess: 'Fișier încărcat cu succes.',
        uploadError: 'Eroare în timpul încărcării fișierelor.',
        uploadInProgress: 'Se încarcă...',
        uploadedFiles:
          '{număr, plural, un {1 fișier încărcat} câteva {{number} fișiere încărcate} alte {{number} fișiere încărcate}}',
      },
      error: 'Eroare!',
      validations: {
        isRequired: 'Câmpul {field} este obligatoriu.',
        minLengthIsRequired:
          'Sunt necesare cel puțin {number, plural, one {1 articol} few {{number} articole} other {{number} articole}}.',
        minValue: 'Valoarea minimă permisă este {value}.',
        maxValue: 'Valoarea maximă permisă este {value}.',
        passwordMatchError: 'Parolele nu se potrivesc',
        invalidEmail: 'E-mail invalid.',
        invalidPhoneNumber:
          'Numar de telefon invalid. Introduceți numărul de telefon internațional  „+ X XXX XXX XXX ”.',
        notValidNumber: 'Numar invalid',
        invalidUrl: 'URL invalid.',
        positive: 'Numărul trebuie să fie pozitiv.',
        negative: 'Numărul trebuie să fie negativ.',
      },
      login: {
        title: '',
        username: 'Nume de utilizator',
        password: 'Parola',
        loginButton: 'Loghează-te',
        error: 'Autentificare eșuată. Numele de utilizator sau parola nu au fost găsite',
        pleaseRelogin: 'Se pare că nu sunteți conectat. Vă rugăm să vă autentificați din nou.',
      },
      days: '{count, plural, one {1 zi} few {{formattedCount} zile} other {{formattedCount} zile}}',
      overdue: 'întârziere {count, plural, one {1 zi} few {{formattedCount} zile} other {{formattedCount} zile}}',
      newItem: 'Nou',
      anonymizing: {
        confirm: {
          title: '',
          content: '',
        },
        button: '',
      },
      appMenu: {
        discover: '',
      },
      missingType: '',
      missingTypeForEntityType: '',
      emptyScoringBlog: '',
    },
    components: {
      buttons: {
        showOnMap: '',
      },
      filters: {
        filterButtonSubmit: 'Căutare',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Adăugarea unei condiții',
        resetFilter: 'Filtrul Resetare',
        operators: {
          contains: {
            0: 'Conţine',
            _: 'Conţine',
          },
          containsexact: {
            0: 'conține exact',
            _: 'conține exact',
          },
          notcontains: {
            0: 'nu conține',
            _: 'nu conține',
          },
          betweennumber: {
            0: 'din',
            1: 'până',
            _: 'între',
          },
          betweendate: {
            0: 'din',
            1: 'până',
            _: 'între',
          },
          equal: {
            0: 'este egal',
            _: 'este egal',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Total articole: {number}',
        nextPage: 'Încărcare {number} elemente',
        emptyMessage: 'Elemente care nu au fost găsite.',
      },
      selects: {
        emptyOption: 'Nici unul',
        emptyMessage: 'Articolele nu au fost găsite.',
      },
      tables: {
        labelRowsPerPage: 'Rânduri pe pagină',
        sortBy: 'Sortați după {field}',
        recordsChanged: 'Unele înregistrări au fost adăugate sau modificate.',
      },
      textField: {
        errorMsgWrapper: 'Eroare: {msg}',
      },
      dateField: {
        invalidDateFormat: 'Format de dată incorect.',
        invalidDate: 'Necunoscut',
        maxDateMessage: 'Data nu trebuie să fie mai mare decât data maximă',
        minDateMessage: 'Data nu trebuie să fie mai mică decât data minimă',
        today: 'Azi',
      },
      detailNavigation: {
        scoringblog: 'Punctaj blog',
        card: 'Card',
        relations: 'Sesiune',
      },
      manipulationInfo: {
        created: 'Creat',
        updated: 'Modificat',
        removed: 'Şterse',
      },
    },
  },
};

export default messages;
