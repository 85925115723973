const messages = {
  ro: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Coș',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parametri',
        },
      },
      productAvailability: {
        format: {
          available: 'În stoc',
          moreThanX: 'Mai mult de {min} bucați în stoc',
          exactCount: '{value} bucați în stoc ',
          notAvailable: 'Indisponibil',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} mai multe',
      },
      relatedProducts: {
        title: 'Produse asemănătoare',
      },
      productAccessories: {
        title: 'Accesorii',
      },
      productDetailImageGallery: {
        noImage: 'Fără poză',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Când voi primi marfa?',
      },
    },
  },
};

export default messages;
