import PropTypes from 'prop-types';
import comOrdersMessages from './react-com-orders/ro';
import comMessages from './react-com/ro';
import formsMessages from './react-forms/ro';
import compMessages from './react-mui-components/ro';
// import fieldMessages from './react-mui-fields/sr';
// import pagesMessages from './react-mui-pages/sr';
import tablesMessages from './react-mui-tables/ro';
import shoppingCartMessages from './react-shopping-cart/ro';
// import shoppingListMessages from './react-shopping-lists/sr';
import messages from './ro';

// const rename = ({ sr }) => ({ ro: sr });

function MessagesRo({ children }) {
  return children(
    [
      compMessages,
      // fieldMessages,
      tablesMessages,
      formsMessages,
      // pagesMessages,
      comMessages,
      // shoppingListMessages,
      shoppingCartMessages,
      comOrdersMessages,
      // invoicesMessages,
      messages,
    ], // .map(rename),
  );
}

MessagesRo.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesRo;
