const messagesRO = {
  ro: {
    parameters: {
      yes: 'Da',
    },
    com: {
      phase: {
        label: 'Denumirea',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'SKU',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Producător',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Numărul piesei',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Garanție',
          months: 'luni',
        },
      },
      totalAmount: {
        label: 'Total',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} mai mult',
    },
    orders: {
      actions: {
        all: 'Toate comenzile',
      },
      myOrdersScoringBlog: {
        title: 'Comenzile mele',
      },
      ordersList: 'Comenzi',
      phase: {
        1: 'Acceptată',
        10: 'Considerate',
        20: 'Se așteaptă plata',
        30: 'Plătit',
        98: 'Sortați',
        99: 'Anulată',
        '01-new': 'Acceptată',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Număr pachet',
            phase: 'Condiție',
            paid: 'Plătit',
            total: 'Fără TVA',
            totalWithTax: 'Total',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'COȘ',
        buyButton: 'Trimite un mesaj',
        emptyBasket: 'Coșul tău este gol',
        buyThere: 'Am mai cumpărat de aici',
        login: 'Vreau să mă loghez',
        notFill: 'Detaliile livrării nu sunt completate',
        fill: 'Actualizați datele',
        change: 'Schimbare de date',
        popupButton: 'Arată pe hartă',
        payment: {
          title: 'PLATĂ',
          free: 'Liber',
          changePaymentBtn: 'MODIFICĂRI LA ACȚIUNE',
        },
        shipping: {
          title: 'LIVRARE',
          free: 'Liber',
          changeShippingBtn: 'SCHIMBĂ MODUL DE LIVRARE',
          popup: {
            title: 'Informație',
            text: 'Livrare la domiciliu direct cu serviciu de curierat {service}',
          },
        },
      },
      actions: {
        all: 'Toate coșurile',
      },
      myBasketsScoringBlog: {
        title: 'Coșuri salvate',
      },
      cartSummaryParameters: {
        caption: 'Codul produsului',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'DESCRIERE',
      parameters: 'Parametrii',
      package: 'Păstrați pachetul',
      fotoAndVideo: 'Faceți clic pe videoclip',
      alternatives: 'Înlocuitor',
      alternativesText: 'Din păcate, în prezent nu avem un înlocuitor pentru produsul pe care l-ați selectat.',
      questions: 'Întrebări',
      productDescriptionNotAvailable: 'Descrierea produsului nu este disponibilă',
    },
    newsletter: {
      phone: '+40 753 689 683',
      subscribe: {
        title: 'Nu renunța la ofertele speciale',
        inputText: 'Adresa ta de email',
        button: 'înscrie-te',
      },
    },
    globalNavigation: {
      home: 'Acasă',
      cumpărături: 'Cumpărături',
      orders: 'Comenzi',
      shoppingLists: 'Liste de cumpărături',
      articles: 'Produse',
    },
    homePageWhy: {
      0: {
        title: 'Cumpărături în siguranță',
        content:
          'Suntem membru cu drepturi depline al Asociației de comerț electronic și garantăm cumpărături online sigure.',
      },
      1: {
        title: 'Suport pentru utilizatori',
        content: 'Satisfacția clienților este importantă pentru noi, așa că suntem mereu alături de tine.',
      },
      2: {
        title: 'Mărfuri în depozit propriu',
        content: '98% din întreaga gamă în stoc și gata pentru expediere imediată.',
      },
      3: {
        title: 'Transport gratuit',
        content: 'Prin achiziționarea de camere și lentile digitale, veți obține transport GRATUIT în România.',
      },
      title: 'DE CE CUMPARAȚI DE LA NOI?',
    },
    startPage: {
      title: 'Pagina principală',
      gotoShopping: 'Pentru a cumpăra',
    },
    shoppingCart: {
      buyBtn: 'ADAUGĂ ÎN COȘ',
      alternatives: 'Înlocuitor',
      shipping: {
        changePoint: 'schimb valutar',
      },
      agreements: {
        termsRequired: 'Regulile trebuie respectate.',
      },
      validation: {
        someProductNotAvailable: 'Unele produse sunt in stoc limitat.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Codul produsului:',
    },
    productListPage: {
      title: 'Catalog',
      orders: {
        recommended: 'Recomandat',
        name: 'Nume',
        priceAsc: 'Crescător dupa preț',
        priceDesc: 'Desrescător dupa preț',
      },
    },
    orderStatuses: {
      storno: 'Anulare',
      synchronized: 'Trimis',
      waiting: 'Se așteaptă sincronizarea',
    },
    orderPreview: {
      items: 'Articole',
    },
    cartRegisterForm: {
      title: 'Formular de înregistrare',
    },
    cartPayment: {
      title: 'Plată',
    },
    cartPreSummaryPage: {
      title: 'Rezumatul coșului',
      actions: {
        nextStep: 'Sunt de acord si continui',
      },
    },
    cartCompanyForm: {
      description: 'Vă rog completați dacă cumpărați ca și companie.',
    },
    cartDeliveryForm: {
      description: 'Completați dacă adresa de livrare diferă de adresa de facturare.',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Când voi primi produsele?',
    },
    productDetailStockInfo: {
      available: 'Avem produsul în depozitul nostru de distribuție.',
      external: 'Produsul este în drum spre depozitul nostru',
      notAvailable: 'Produsele sunt temporar indisponibile',
      labels: {
        free: 'LIVRARE GRATUITA',
        showroom: 'INCLUS ÎN COMANDĂ',
      },
    },
    productGifts: {
      btnName: 'CADOU',
      giftValue: 'În valoare de',
    },
    homepage: {
      productOfMonth: 'Produsele lunii',
      catalogFavorite: 'Categorii preferate',
      hotTips: 'Ofertă exclusivă',
      recommended: 'Recomandat',
    },
    productQuestion: {
      firstName: 'Prenume',
      lastName: 'Nume de familie',
      email: 'Email',
      subject: 'Subiect',
      question: 'Întrebare',
      gdpr: 'Prin trimiterea unei solicitări, accept prelucrarea datelor cu caracter personal pentru a răspunde solicitării.',
      gdprLink: 'Link GDPR',
      button: 'Trimiteți o întrebare',
      subjectContent: 'Întrebare despre articol: {product}, cod: {sku}',
      title: 'Trebuie să fiți deacord cu prelucrarea datelor cu caracter personal.',
      success: 'Trimis cu succes',
    },
    lastVisitedProducts: 'Produse vizitate recent',
    productAlternativeProducts: 'Alternative',
    productAccessoryProducts: 'Livrare',
    productReplacementProducts: 'Înlocuitor',
    productAccessories: 'Accesorii',
    productReplacements: 'Înlocuitor',
    appProductDetailTabs: {
      description: 'Descriere',
      parameters: 'Parametrii',
      package: 'Conținutul pachetului',
      query: 'Întrebare',
      media: 'Faceți clic pe videoclip',
      alternatives: 'Alternative',
      accessories: 'Livrare',
      replacements: 'Înlocuitor',
      form: {
        enum: '!!!! Nu este un număr',
      },
    },
    cartSummaryPage: {
      title: 'Rezumat înainte de a comanda',
      actions: {
        continueShopping: 'continuă cumpărăturile',
        saveOrder: 'Creați o comandă',
      },
      expectedDeliveryDate: {
        label: 'Data de livrare obligatorie',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Notă pentru furnizori',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Gateway de plată',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Profil utilizator',
      content: 'Scor',
      contactInfo: {
        newsletter: {
          title: 'Notificare prin e-mail',
          content: 'Aș dori să știu despre site-ul Digiexpert.ro',
        },
        title: 'DATELE MELE',
      },
      orders: {
        title: 'Istoric comenzi',
      },
      invoices: {
        title: 'Facturile mele',
      },
      myOrders: 'Comenzile mele',
      myInvoices: 'Facturile mele',
      logout: 'Deconectați-vă',
      login: 'Întoarce-te',
      favorites: {
        title: 'OPȚIUNE FAVORITĂ',
      },
      changePassword: {
        title: 'Schimbați parola',
        fields: {
          login: 'Autentificare (nu poate fi schimbat)',
          originalPassword: 'Parola originală',
          newPassword: 'Parolă Nouă',
          confirmNewPassword: 'Confirmă parola',
        },
        button: 'Salvează modificările',
      },
    },
    orderSavedThankPage: {
      title: 'Vă mulțumim pentru comanda dumneavoastră {orderNumber}',
      thankMessage: {
        template:
          'Verificați adresa de e-mail pentru a vă confirma comanda. Păstrați e-mailul pentru uz propriu. Mai jos veți găsi informații despre comanda dvs',
        phoneNumber: '',
        email: 'info@digiexpert.ro',
      },
      order: 'Numărul comenzii:',
      payment: 'Plata zilei:',
      cancelled: 'Anulați',
      accepted: 'Acceptată',
      pending: 'În derulare',
      paid: 'Plătit',
      actions: {
        back: 'Înapoi la pagina de start',
        detail: 'Detaliile comenzii',
        continue: 'Continuă cumpărăturile',
      },
      decline: 'Respins',
    },
    shoppingLists: {
      title: 'Liste de cumpărături',
      actions: {
        saveAsShoppingList: 'Salvați ca listă de cumpărături',
        importShoppingListToCart: 'Importați',
      },
    },
    searchResultPage: {
      search: 'Termen de căutare:',
      results: 'Rezultatele căutării',
    },
    homePageArticles: {
      title: 'Articole noi',
      button: 'Afișați toate articolele',
    },
    loginPage: {
      title: 'Autentificare',
      forgotPassword: 'Resetează parola',
      email: 'E-mail',
      sendEmail: 'Trimite un email',
      info: 'Veți primi un e-mail cu un link pentru a vă schimba parola',
      password: 'Parola',
      notSame: 'nu este la fel',
      newPassword: 'Parolă nouă',
      confirmPassword: 'Confirmați parola',
      changePassword: 'Schimbarea parolei',
    },
    productPrice: {
      withoutTax: 'Fără TVA',
    },
    productDetailPage: {
      availability: {
        dateRange: [
          '{minDays, select,',
          '  2 {Comandă acum {today} și fă-o timp de {minDays} - {maxDays} pentru a ți-l oferi.}',
          '  3 {Comandă acum {today} și fă-o timp de {minDays} - {maxDays} pentru a ți-l oferi.}',
          '  other {Comandă mai multe {today}. Vă va fi livrat în {minDays} - {maxDays} zile.}',
          '}',
        ],
        today: 'Comandați până la ora 13:30 și mărfurile vor părăsi depozitul în aceeași zi.',
        todayWord: 'azi',
        tomorrow: 'Comandaţi {today} și mărfurile vor părăsi depozitul mâine.',
        dayOfWeek: {
          1: 'luni',
          2: 'marţi',
          3: 'miercuri',
          4: 'joi',
          5: 'vineri',
          6: 'sâmbătă',
          0: 'duminică',
          message: 'Comandați {today} și comanda vă va fi expediată in {day} fiind zi lucrătoare.',
        },
        workingDay: 'Comandați înainte de 13:30 și livrăm',
        afterWorkingDay: 'Comandați azi și livrăm',
      },
      allDescription: 'DESCRIEREA COMPLETĂ A PRODUSULUI',
      demoProduct: {
        text: 'CUMPĂRĂ MAI IEFTIN LA:',
      },
      productNote: {
        title: 'CUMPĂRĂ MAI IEFTIN LA: xxxxx Lei',
        note: 'Nota 2 - Descrierea suplimentară a statusului sau informații similare despre produs, de ce este redus și ce este pe stoc...',
      },
      adornments: {
        notForSale: 'Nu se vinde separat',
        usedProduct: 'Outlet',
        freeGift: 'Cadou gratuit',
        freeShipping: 'livrare gratuita',
        eol: 'Vânzare finalizată',
        demo: 'Produs de prezentare',
        sale: 'Reducere',
      },
      disponibility: {
        primaryLessThan: 'ÎN STOC mai puțin de {count} bucăți',
        primaryMoreThan: 'ÎN STOC {count, plural, zero {o bucată} other {{count} și mai multe piese}}',
        secondaryLessThan: 'ÎN STOC mai puțin de {count} bucăți',
        secondaryMoreThan: 'ÎN STOC {count, plural, zero {o bucată} other {{count} și mai multe bucăți}}',
        external: 'Produsul este în drum spre depozitul nostru',
        unavailable: 'Marfa nu este disponibilă',
        showroom: '(expus în Showroom)',
        soldout: 'Marfa epuizată',
        'on-way': {
          d: '{from, plural, one {Livrat mâine} two {Livrat poimâine} few {Livrat în {from} zile} other {Livrat în {from} zile}}',
          m: 'Pe drum în {from, plural, one {minut} two {minute} other {minute}}',
          M: 'Pe drum în {from, plural, one {lună} two {luni} few {luni} other {luni}}',
          dRange: 'Pe drum în {from, plural, câteva {{from} - {to} zile} other {{from} - {to} zile}}',
          mRange: 'Pe drum în {from} - {to, plural, one {zi} few {zile} other {zile}}',
          MRange: 'Pe drum în {from} - {to, plural, one {lună} two {luni} few {luni} other {luni}}',
          date: '{date}',
        },
        time: 'Pe drum pentru {count, plural, zero {o bucată} other {{count} și mai multe piese}}',
      },
    },
    comparePage: {
      title: 'Comparaţie',
      removeButton: 'Selectați un produs',
      nothing: 'Nu există nimic de comparat',
    },
    filter: {
      reset: 'Resetați filtru',
      search: 'Căutare',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Termenii comenzii',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Adaugă în coș',
      },
    },
    invoiceBlogPreview: {
      title: 'Facturile mele',
      button: 'Toate facturile',
    },
    userProfilePage: {
      logout: 'Deconectați-vă',
      setting: 'Setări',
      passwordChange: 'Schimbați parola',
    },
    appBarActionRegion: {
      changeRegion: 'Schimbați limba',
      label: 'Finalizarea',
    },
    subCategories: {
      title: 'Subcategorii',
    },
    aboutShoppingPage: {
      title: 'TOTUL DESPRE CUMPĂRARE',
      contact: 'Contact',
      electronicClosureOfRevenue: 'ÎNREGISTRARE ELECTRONICĂ',
      informationDuty: 'INFORMATIE OBLIGATORIE',
      processingOfPersonalData: 'PRELUCRAREA DATELOR PERSONALE',
      cashback: 'CASHBACK',
      delivery: 'LIVRARE',
      availability: 'DISPONIBILITATE',
      methodOfPayment: 'METODA DE PLATĂ',
      termsAndConditions: 'CONDIȚII',
      termsOfAppeal: 'CONDIȚII DE APEL',
      contractwWithdrawal: 'REZILIEREA CONTRACTULUI',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Numărul fiscal a fost verificat, prețurile se percep fără TVA.',
        viesNotVerified: 'Codul dvs. fiscal nu este verificat sau nu vă calificați pentru o achiziție fără TVA. ',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'INFORMAȚII DE LIVRARE',
      pleaseComplete: 'COMPLETEAZA TE ROG',
      closeButton: 'Finalizarea',
    },
    cartPromoCodes: {
      title: 'APLICAȚI CODUL PROMO',
      textFieldPlaceholder: 'COD PROMO',
      textFieldHelperText: 'Este posibil să se aplice doar un cod promo pe comandă',
      applyPromoCodeButton: 'APLICA',
    },
    cookiesBar: {
      agreeBtn: 'Sunt de acord',
      title: '',
      text: 'Site-ul digiexpert.ro folosește cookie-uri pentru cea mai bună experiență posibilă a vizitatorilor noștri. Continuând să utilizați site-ul, credem că sunteți de acord cu politica noastră de confidențialitate și confirmați acceptarea cookie-urilor.',
    },
    cartFormLogin: {
      btnSend: 'Trimite',
      button: 'Loghează-te',
      title: 'Autentificare',
      forgottenPasswordBtn: 'Ti-ai uitat parola?',
      text1: 'Vom trimite o parolă unică la adresa de e-mail specificată.',
      text2: 'După conectare, setați o nouă parolă.',
      error: 'Nume de utilizator sau parola incorecte.',
    },
    cartSummartSection: {
      title: 'REZUMATUL CONTRACTULUI',
    },
    appCartSummarySubjectsList: {
      title: 'Titlu',
      text: 'Nume/descriere',
    },
    newsletterText: {
      text1: 'Ai nevoie de un sfat?',
      text2: 'Ne puteți suna în fiecare zi lucrătoare de la 9:00 la 17:30.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(fără TVA)',
      priceLabel: 'PREȚ / COSTURI',
      amount: 'Cantitate',
      totalPriceLabel: 'PREȚUL TOTAL',
      totalPrice: 'În total',
      submitButton: 'Trimiteți o comandă',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: 'buc',
    },
    cartSummaryListItem: {
      freeTitle: 'Liber',
      amountTitle: 'buc',
    },
    sxCartNotes: {
      note: 'Nu specifica data de livrare',
      addNote: 'Adaugă o notiță',
    },
    productDetail: {
      promoCode: {
        title: 'cu codul promo:',
        validTo: 'valabil până la:',
      },
      productSendLink: {
        copyLinkBtn: 'Copiați linkul',
        sendLinkByEmail: 'Trimite linkul pe e-mail',
      },
      productDetailBarInformation: {
        addToFavourites: 'Adaugă la favorite',
        compareProduct: 'Comparați produsul',
        sendLink: 'Trimiteți un link',
        serviceList: 'Lista serviciilor',
        questions: 'Întrebări',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'Cumpăr pentru o companie',
        otherDeliveryInfo: 'Adresa de livrare este diferită',
        otherDeliveryInfoAlert: 'Plătitorul de TVA nu poate folosi o altă adresă de livrare.',
      },
      companyNameLabel: 'Companie',
      firstNameLabel: 'Prenume',
      lastNameLabel: 'Nume de familie',
      streetLabel: 'Strada',
      cityLabel: 'Oraș',
      zipLabel: 'Cod poștal',
      companyIDLabel: 'Cod fiscal',
      countryLabel: 'Țară',
    },
    cartFormPersonal: {
      label: 'Adresă de livrare',
      firstNameLabel: 'Prenume',
      lastNameLabel: 'Nume de familie',
      streetLabel: 'Strada / Numărul casei',
      pscLabel: 'Cod poștal',
      cityLabel: 'Oraș',
      countryLabel: 'Țară',
      phoneLabel: 'Număr de telefon',
      emailLabel: 'adresă de e-mail',
      passwordLabel: 'Parola',
      controlPasswordLabel: 'Parola de control',
    },
    cartFormInvoice: {
      labelDesktop: 'Detalii de contact / adresa de facturare',
      labelMobile: 'Informații de contact',
      companyLabel: 'Compania',
      password: 'Parola',
      confirmPassword: 'Verificarea parolei',
      email: 'E-mail',
      firstName: 'Prenume',
      lastName: 'Nume de familie',
      countryLabel: 'Țara',
      phone: 'Telefon',
      phoneError: 'Numărul de telefon este incorect',
      streetLabel: 'Strada / Numărul casei',
      streetNumberLabel: 'numărul străzii/casei',
      zipLabel: 'COD POȘTAL',
      icoLabel: 'CIF',
      dicLabel: 'Codul fiscal',
      country: 'Țara',
      cityLabel: 'Oraș',
      registration: 'Vreau să deschid un cont',
      registrationNote: 'și vă puteți urmări comenzile, transporturile și plățile ...',
      button: 'Salvează modificările',
      label: 'Adresa de facturare',
    },
    page404: {
      title: 'Ne pare rău, pagina nu există...',
      subTitle: 'Continuați la pagina de start.',
      button: 'Reveniți la pagina de start.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Filtrează după:',
      byName: 'Nume',
      byPrice: 'Preț',
      onlyStock: 'Singur în stoc',
      resetFilter: 'Anulare',
      displayCountOfProducts: 'Produse:',
      search: 'Căutare',
      clear: 'Șterge',
      ok: 'OK',
    },
    priceRangeSliderField: {
      title: 'Gama de prețuri',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtrează dupa',
    },
    productListMultipleFilterMobile: {
      title: 'FILTRU',
    },
    cartCompany: {
      companyName: 'Companie',
      regNo: 'CIF',
      taxNo: 'TVA',
      taxNoPlaceholder: 'RO0000000000',
      warningMessage: {
        warning: 'Important.',
        message: 'În cazul livrării la o altă adresă decât cea a companiei, la comandă se va percepe TVA.',
      },
    },
    searchResultsPage: {
      match: 'Pentru {searchText} am găsit {resultCountFormatted} rezultate.',
      nomatch: 'Nu am găsit niciun rezultat pentru {searchText}.',
      matchingCategories: 'Categorii găsite',
      button: 'Reveniți la pagina de start.',
    },
    selectMultipleFilter: {
      totalChipsLabel: '{total} produse selectate',
    },
    cartDelivery: {
      firstName: 'Prenume',
      lastName: 'Nume de familie',
      companyName: 'Companie',
      streetLabel: 'Stradă',
      streetNumberLabel: 'numărul străzii/casei',
      zipLabel: 'Cod poștal',
      country: 'Țara',
      cityLabel: 'Oraș',
      street: 'Strada / Numărul casei',
      zip: 'Cod poștal',
      city: 'Oraș',
    },
    cartNotes: {
      shippingLabel: 'Adăugați o notă la transportator',
      shippingNote: 'Notă pentru transportatori',
      sellerLabel: 'Adăugarea unei note la vânzător',
      sellerNote: 'Notă pentru vânzători',
      loginNote: 'Dacă aveți deja un cont la noi, vom îndeplini totul pentru dvs. după înregistrare.',
    },
    cartCountryShipping: {
      title: 'Țara de livrare',
      label: 'Alegerea țării afectează metoda de plată, livrarea și data livrării',
    },
    cartAddress: {
      bllingAddress: 'Adresa de facturare',
      deliveryAddress: 'Adresa de livrare',
      pickUpPointAddress: 'Adresa punctului de preluare',
    },
    cartAgreements: {
      customerVerified:
        'Nu sunt de acord cu trimiterea unui chestionar care face parte din programul Magazin de încredere, care se desfășoară cu scopul de a măsura satisfacția clienților, în scopul îmbunătățirii serviciilor noastre.',
    },
    loginDialog: {
      username: 'Nume de utilizator',
      password: 'Parola',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Parolele pe care le-ați introdus nu sunt aceleași',
      invalidPostalCode: 'Codul poștal specificat nu este într-un format valid',
      usernameIsNotFree: 'Un utilizator cu acest nume este deja înregistrat.',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Adăugați o nouă factură',
        all: 'Toate facturile',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Număr',
            invoiceDate: 'Data facturii',
            dueDate: 'Termen limită',
            total: 'Fără TVA',
            totalWithTax: 'Total',
            billingAddress: 'Adresa de facturare',
            orderNumber: 'Număr comandă',
            unpaidAmount: 'Sumă neplătită',
          },
          filter: {
            number: 'Număr',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Revizuirea facturii',
          columns: {
            priceUnit: 'Unitate de preț',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Prețul total al comenzii',
      tax: 'TVA',
      withoutTax: 'Preț total fara TVA',
    },
    compare: {
      addToCompare: 'Produsul a fost adăugat în câmpul de comparație',
      removeToCompare: 'Produsul a fost eliminat din câmpul de comparație',
      price: 'Preț',
      ean: 'ean',
    },
    favorite: {
      addToFavorite: 'Produs adăugat la favorite',
      removeFromFavorite: 'Produsul a fost eliminat din favorite ',
    },
    registration: {
      important: 'IMPORTANT!',
      note: 'Vă vom trimite o parolă unică prin e-mail. După autentificare, introduceți o nouă parolă.',
      gdprAgreement: 'Trebuie să fiți de acord cu prelucrarea datelor cu caracter personal',
      submitButton: 'Înregistreaza-te',
      fail: 'Înregistrarea a eșuat, vă rugăm să încercați din nou mai târziu',
      success: 'Înregistrare reușită',
      title: 'Înregistrare',
      dialog: 'Dacă încă nu aveți un cont la noi, vă puteți înregistra',
      here: 'Aici',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Caută produse',
    },
    appBarActions: {
      about: 'Despre cumpărături',
      aboutHref: '/despre-cumparaturi',
      compare: 'Comparaţie',
    },
    appBarActionUser: {
      title: 'Aplicație/autentificare',
    },
    footer: {
      copyright: 'DREPTURI © ČESKÝ VELKOOBCHOD S.R.O. TOATE DREPTURILE REZERVATE',
    },
    appArticlesPage: {
      title: 'Text',
    },
    errorPage: {
      title: 'Ceva este greșit.',
      button: 'Pagina principala',
    },
    cartSummary: {
      withoutTax: 'fără TVA',
    },
    renewPasswordForm: {
      success: 'O nouă parolă a fost trimisă la adresa dvs. de e-mail.',
      emailNotFound: 'Nu s-a găsit e-mailul!',
      error: 'Ceva este greșit',
    },
    cartSubjectListItemQuantityWarning: 'Cantitatea curentă de produs este de {count}',
  },
};

export default messagesRO;
