const messages = {
  ro: {
    orders: {
      actions: {
        addNewOrder: 'Adăugați o comandă',
        all: 'Toate comenzile',
      },
      listPage: {
        title: 'Comenzi',
        table: {
          filter: {
            number: 'Număr',
          },
          columns: {
            contact: 'Contact',
            dateOfOrder: 'Data',
            number: 'Număr',
            offer: 'Ofertă',
            opportunity: 'Oportunitate',
            owner: 'Proprietar',
            state: 'Status',
            total: 'Total',
            totalUserCurrency: 'Total în {currency}',
          },
        },
      },
      order: {
        newEntity: 'Comandă nouă',
        contact: {
          label: 'Contact',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Comision total',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Monedă',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Data comenzii',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Număr',
          helperText: 'Lăsați necompletat pentru generarea automată a numărului.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Ofertă',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Oportunitate',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Proprietar',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Marjă comercială',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Subiect',
          discount: {
            label: 'Reducere',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Comision',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Mărimea pachetului',
          },
          listPrice: {
            label: 'Lista de prețuri',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Prețul de vânzare',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produs',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Cantitate',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Marjă comercială',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Pentru totalul articolului',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Unitate',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{număr,plural, = 0 {fără articole} un {1 articol} câteva {{number} articole} alte {{number} articole}}',
      },
      myOrdersScoringBlog: {
        title: 'Comenzile mele',
      },
      ordersList: 'Comenzi',
    },
  },
};

export default messages;
