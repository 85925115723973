const messages = {
  ro: {
    sprinxFormsValidation: {
      required: 'Câmpul este obligatoriu!',
      string: 'Trebuie să fie un rând de text!',
      stringEmpty: 'Nu trebuie să fie gol!',
      stringMin: 'Lungimea trebuie să fie mai mare sau egală cu {expected} caractere!',
      stringMax: 'Lungimea trebuie să fie mai mică sau egală cu {expected} caractere!',
      stringLength: 'Lungimea trebuie să fie de {expected} caractere!',
      stringPattern: 'Nu îndeplinește modelul cerut!',
      stringContains: 'Trebuie să conțină textul „{expected}”!',
      stringEnum: 'Nu se potrivește cu niciuna dintre valorile permise!',
      stringNumeric: 'Trebuie să fie un număr!',
      number: 'Trebuie să fie un număr!',
      numberMin: 'Trebuie să fie mai mare sau egal cu {expected}!',
      numberMax: 'Trebuie să fie mai mic sau egal cu {expected}!',
      numberEqual: 'Trebuie să fie egal cu {expected}!',
      numberNotEqual: 'Nu poate fi egal cu {expected}!',
      numberInteger: 'Trebuie să fie un număr întreg!',
      numberPositive: 'Trebuie să fie un număr pozitiv!',
      numberNegative: 'Trebuie să fie un număr negativ!',
      array: 'Trebuie să fie o listă!',
      arrayEmpty: 'Nu trebuie să fie o listă goală!',
      arrayMin:
        'Cel puțin {număr, plural, un {1 articol} câteva {{number} articole} alte {{number} articole}} necesare!',
      arrayMax:
        'Nu poate conține mai mult de {număr, plural, un {1 articol} câteva {{number} articole} alte {{number} articole}}!',
      arrayLength:
        'Trebuie să conțină exact {așteptat, plural, one {1 articol} câteva {{number} articole} alte {{number} articole}}!',
      arrayContains: "Trebuie să conțină un articol'{expected}'!",
      arrayEnum: 'Valoarea „{expected}” nu se potrivește cu niciuna dintre valorile permise!',
      boolean: 'Trebuie să fie „da” sau „nu”!',
      function: 'Trebuie să existe caracteristici!',
      date: 'Trebuie să fie data!',
      dateMin: 'Trebuie să fie mai mare sau egal cu {expected}!',
      dateMax: 'Trebuie să fie mai mic sau egal cu {expected}!',
      forbidden: 'Câmpul este interzis!',
      email: 'E-mail invalid!',
      url: 'Adresă URL greșită!',
      object: 'Trebuie să fie de tip Obiect!',
      arrayReducedEquals: 'Valoarea rezumată nu se potrivește {expected}!',
      phoneNumber: 'Trebuie să fie un număr de telefon internațional valid pentru „+ XXX XXX XXX XXX”!',
      dateGt: 'Data trebuie să fie mai mare decât {expected}!',
      dateGte: 'Data trebuie să fie mai mare sau egală cu {expected}!',
      dateLt: 'Data trebuie să fie mai mică decât {expected}!',
      dateLte: 'Data trebuie să fie mai mică sau egală cu {expected}!',
    },
  },
};

export default messages;
