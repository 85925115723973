const messages = {
  ro: {
    reactMuiTables: {
      selected: 'Selectat',
      filterhide: 'Ascunderea filtrului',
      filtershow: 'Afișare filtru',
      refresh: 'Reîncarcă',
      filter: {
        actions: {
          search: 'Căutare',
          resetFilter: 'Resetați filtrul',
          addCondition: 'Adăugarea unei condiții',
        },
      },
      standardOperators: {
        equals: 'Egal',
        contains: 'Conţine',
        between: {
          label: 'între',
          from: 'din',
          to: 'până',
        },
        oneOf: 'una dintre',
      },
      labelRowsPerPage: 'Linii pe pagină:',
    },
  },
};

export default messages;
