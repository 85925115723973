const messages = {
  ro: {
    shoppingCart: {
      actions: {
        addToCart: 'Adaugă în coş',
        buy: 'ÎN COȘ',
        order: 'Comandă',
        remove: 'Elimina',
      },
      cart: {
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Total pe rânduri',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Total pentru transport',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Total pentru plată',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Total impozite',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Resetați coșul',
      },
      messages: {
        confirmResetCart: 'Sigur doriți să ștergeți conținutul coșului?',
        giftClaim: 'Puteți primi cadou {quantity} bucăți din {productName}.',
        cartManipulations: {
          allRemoved: 'Toate articolele din coș au fost șterse.',
          itemAdded: 'Adaugă în coș {number, plural, one {articol adăugat} other {articole adăugate}}.',
          itemRemoved: 'Din coș {number, plural, one {element șters} other {elemente șterse}}.',
          quantityChaged: 'Cantitatea achiziționată s-a schimbat.',
        },
      },
      components: {
        appBartActionCart: 'Coș',
        quantityField: {
          states: {
            quantityRounded: 'Cantitate rotunjită la cea mai apropiată dimensiune a ambalajului.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Coșul este gol',
        },
        cartAfterAddModal: {
          continueShopping: 'Continuați cumpărăturile',
          goToCart: 'Du-te la coș',
          title: 'Produse adăugate în coș',
          pcs: 'buc',
        },
      },
    },
  },
};

export default messages;
